import React from 'react';
import {useEffect} from 'react';
import {Link} from "react-router-dom";
import { analytics } from '../../Firebase.js';
import { logEvent } from "firebase/analytics";
import './style.css';
import './newStyle.css';

import logoKS from '../../images/logoKS.png';
import middle1 from '../../images/inicio/middle1.png';
import trianglesLeft from '../../images/inicio/triangles-left.png';
import trianglesRight from '../../images/inicio/triangles-right.png';
import middle2_1 from '../../images/inicio/middle2_1.png';
import middle2_2 from '../../images/inicio/middle2_2.png';
import middle3 from '../../images/inicio/middle_3.png';
import combate1 from '../../images/inicio/combate1.png';
import combate2 from '../../images/inicio/combate2.png';
import combate3 from '../../images/inicio/combate3.png';
import combate4 from '../../images/inicio/combate4.png';
import combate5 from '../../images/inicio/combate5.png';
import combate6 from '../../images/inicio/combate6.png';
import combate7 from '../../images/inicio/combate7.jpg';
import circuito1 from '../../images/inicio/circuito1.png';
import circuito2 from '../../images/inicio/circuito2.png';
import circuito3 from '../../images/inicio/circuito3.png';
import circuito4 from '../../images/inicio/circuito4.png';
import circuito5 from '../../images/inicio/circuito5.png';
import poomsae1 from '../../images/inicio/poomsae1.png';
import poomsae2 from '../../images/inicio/poomsae2.png';
import poomsae3 from '../../images/inicio/poomsae3.jpeg';
import poomsae4 from '../../images/inicio/poomsae4.png';
import middle6_1 from '../../images/inicio/middle6-1.png';
import middle6_2 from '../../images/inicio/middle6-2.png';
import middle6_3 from '../../images/inicio/middle6-3.png';

const Inicio = () => {
    useEffect(() => {
        document.title ="Ki Systems - Sistema de marcación taekwondo";
        document.getElementsByTagName("html")[0].classList = "";
        document.getElementsByTagName("html")[0].classList.add("styleNewPage");

        Array.from(document.getElementsByTagName("html")[0].getElementsByClassName("animated")).forEach(e => e.style.visibility = "hidden");
        let observer = new IntersectionObserver((e) => {
            if(!e[0].isIntersecting) {
                observer.unobserve(e[0].target);
                console.log("se efectua");
                Array.from(e[0].target.nextElementSibling.getElementsByClassName("animated")).forEach(el => {
                    el.style.visibility = "visible";
                    el.classList.add("animate__animated");
                    if(el.classList.contains("fadeInUp")) { el.classList.add("animate__fadeInUp"); }
                    if(el.classList.contains("zoomIn")) { el.classList.add("animate__zoomIn"); }
                    if(el.classList.contains("fadeInLeft")) { el.classList.add("animate__fadeInLeft"); }
                    if(el.classList.contains("bounceIn")) { el.classList.add("animate__bounceIn"); }
                    if(el.classList.contains("heartBeat")) { el.classList.add("animate__heartBeat"); }
                });
            }
        }, { rootMargin: "-50%" });
        observer.observe(document.getElementById("top"));
        observer.observe(document.getElementById("middle-1"));
        observer.observe(document.getElementById("middle-2"));
        observer.observe(document.getElementById("middle-5"));

        const portadaCombate = setInterval(cambiarPortadaCombate, 4000);
        const portadaPoomsae = setInterval(cambiarPortadaPoomsae, 4000);
        const portadaCircuito = setInterval(cambiarPortadaCircuito, 4000);

        return () => {
            clearInterval(portadaCombate);
            clearInterval(portadaPoomsae);
            clearInterval(portadaCircuito);
        }
    }, []);

    let indexPortadaCombate = 1;
    const portadasCombate = [combate1, combate2, combate3, combate4, combate5, combate6, combate7];
    const cambiarPortadaCombate = () => {
        document.getElementById("portadaCombate").src = portadasCombate[indexPortadaCombate];
        indexPortadaCombate++;
        if(indexPortadaCombate == 7) { indexPortadaCombate=0; }
    };

    let indexPortadaPoomsae = 1;
    const portadasPoomsae = [poomsae1, poomsae2, poomsae3, poomsae4];
    const cambiarPortadaPoomsae = () => {
        document.getElementById("portadaPoomsae").src = portadasPoomsae[indexPortadaPoomsae];
        indexPortadaPoomsae++;
        if(indexPortadaPoomsae == 4) { indexPortadaPoomsae=0; }
    };

    let indexPortadaCircuito = 1;
    const portadasCircuito = [circuito1, circuito2, circuito3, circuito4];
    const cambiarPortadaCircuito = () => {
        document.getElementById("portadaCircuito").src = portadasCircuito[indexPortadaCircuito];
        indexPortadaCircuito++;
        if(indexPortadaCircuito == 4) { indexPortadaCircuito=0; }
    };

	return(
		<React.Fragment>
            <header>
                <div>
                    <img src={logoKS} alt="Logo Ki Systems"/>
                    <a href='#'>SMTKD</a>
                    <Link to="/servicios">Servicios</Link>
                </div>
                <a className='contact-button' href="/clientes" target='_blank'>
                    <i className='bx bxs-user-circle'></i>
                    <span>Mi cuenta de cliente</span>
                </a>
            </header>

            <main>
                <div id='top'>
                    <div className='product-name'>
                        <span className='animate__animated animate__fadeIn anim-delay-3s-400ms'>SMTKD</span>
                        <div>
                            <div className="arrow animate__animated animate__fadeInLeftBig anim-delay-2s-600ms"></div>
                            <div className="arrow animate__animated animate__fadeInLeftBig anim-delay-2s-400ms"></div>
                            <div className="arrow animate__animated animate__fadeInLeftBig anim-delay-2s-200ms"></div>
                        </div>
                    </div>

                    <div className='title-content'>
                        <h1 className='animate__animated animate__fadeInUp animate__slow'>Sistema de marcación<br/>Taekwondo</h1>
                        <div>
                            <i className="fa-brands fa-windows animate__animated animate__zoomIn anim-delay-1s-200ms"></i>
                            <i className="fa-brands fa-apple animate__animated animate__zoomIn anim-delay-1s-700ms"></i>
                        </div>
                    </div>

                    <div className="visual-help">
                        <span>Scroll</span>
                        <div>
                            <i style={{'--animate-duration': '8s'}} className="fa-solid fa-angle-down animate__animated animate__shakeY animate__slow animate__infinite anim-delay-400ms"></i>
                            <i style={{'--animate-duration': '8s'}} className="fa-solid fa-angle-down animate__animated animate__shakeY animate__slow animate__infinite anim-delay-200ms"></i>
                            <i style={{'--animate-duration': '8s'}} className="fa-solid fa-angle-down animate__animated animate__shakeY animate__slow animate__infinite"></i>
                        </div>
                    </div>
                </div>

                <div id='middle-1'>
                    <div id='s-middle-1'>
                        <img src={trianglesLeft} className='triangles-left' alt="graphic"/>
                        <div>
                            <h2 className='animated fadeInUp'>SMTKD <span>©</span></h2>
                            <p className='animated fadeInUp anim-delay-500ms'>El sistema de marcación convencional para Taekwondo más completo del mercado</p>
                        </div>
                        <img src={trianglesRight} className='triangles-right' alt="graphic"/>
                        <img className='animated zoomIn anim-delay-900ms' src={middle1} alt="Entrenamiento con SMTKD" />
                    </div>
                </div>

                <div id='middle-2'>
                    <div id="s-middle-2">
                        <div className='images-container'>
                            <img src={middle2_1} className='animated zoomIn anim-delay-900ms' alt="Configuración de controles"/>
                            <img src={middle2_2} className="gamepad animated fadeInUp anim-delay-1s-400ms" alt="Control PS4"/>
                        </div>

                        <div className='text-container'>
                            <p className='animated fadeInUp'>Marque usando mandos inalámbricos compatibles con PS4 o Xbox 360/One, solo los vincula a su PC y listo!</p>
                            <span className='animated fadeInUp anim-delay-500ms'><b>No</b> se requiere ningún software adicional para el uso de los controles</span>
                        </div>

                        <img src={trianglesLeft} className='triangles-left' alt="graphic"/>
                    </div>
                </div>

                <div id="middle-3">
                    <div id="s-middle-3">
                        <div className="text-container">
                            <h2 className='animated fadeInUp'>Requisitos mínimos</h2>
                            <p className='animated fadeInUp anim-delay-500ms'>
                                Windows 7, 10, 11 o MacOS<br/>
                                Procesador de 64 bits<br/>
                                120mb libres de almacenamiento<br/>
                                Bluetooth*<br/>
                                <i>(para conectar controles sin cables)</i>
                            </p>
                        </div>

                        <img className='animated zoomIn anim-delay-900ms' src={middle3} alt="PC con SMTKD" />

                        <span className='animated fadeInUp anim-delay-1s-200ms'>*Si su PC no cuenta con Bluetooth, puede adquirir un adaptador Bluetooth USB 4.0 o 5.0</span>
                    </div>
                </div>

                <div id="middle-4">
                    <div className="header">
                        <h2>Módulos</h2>
                        <p>Modos de marcación del sistema</p>
                    </div>

                    <div className="card-container">
                        <div className="card" id="card-combate">
                            <span className="title">COMBATE</span>
                            <img id='portadaCombate' src={combate1} alt="Pantalla Combate" onClick={() => cambiarPortadaCombate()}/>
                            <div>
                                <span><b>∙</b> Alineado con el reglamento vigente WT</span>
                                <span><b>∙</b> Al mejor de 3</span>
                                <span><b>∙</b> Desempate por complejidad de técnica</span>
                                <span><b>∙</b> Tiempos configurables</span>
                                <span><b>∙</b> Estadísticas detalladas</span>
                                <span><b>∙</b> Uso con 1 a 3 controles (Jueces)</span>
                            </div>
                        </div>

                        <div className="card" id="card-poomsae">
                            <span className="title">POOMSAE</span>
                            <img id='portadaPoomsae' src={poomsae1} alt="Pantalla Poomsae" onClick={() => cambiarPortadaPoomsae()}/>
                            <div>
                                <span><b>∙</b> Poomsae reconocido o estilo libre</span>
                                <span><b>∙</b> Marcación por técnica y presentación</span>
                                <span><b>∙</b> Modo individual y modo versus</span>
                                <span><b>∙</b> Estadísticas detalladas</span>
                                <span><b>∙</b> Uso con 5 ó 7 controles (Jueces)</span>
                            </div>
                        </div>

                        <div className="card" id="card-circuito">
                            <span className="title">CIRCUITO</span>
                            <img id='portadaCircuito' src={circuito1} alt="Pantalla Circuito" onClick={() => cambiarPortadaCircuito()}/>
                            <div>
                                <span><b>∙</b> Marcación por penalizaciones o puntos</span>
                                <span><b>∙</b> Uno o dos competidores</span>
                                <span><b>∙</b> Cronómetro preciso</span>
                                <span><b>∙</b> Uso con 1 o 2 controles (Número de competidores)</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="middle-5">
                    <h2>Reseñas</h2>
                    <div className='reviews-container'>
                        <div>
                            <iframe src="https://drive.google.com/file/d/1ZDSipMjX77okgN3lL8pXmndGw9rvKSzD/preview" allow="autoplay"></iframe>
                        </div>
                        <div>
                            <iframe src="https://drive.google.com/file/d/1EOeIHx9_esMOJJp3xvrCW_l9T_OZxoGb/preview" allow="autoplay"></iframe>
                        </div>
                        <div>
                            <iframe src="https://drive.google.com/file/d/1We38oGwXMKVZTh6QbwtN110bbw7OJgVy/preview" allow="autoplay"></iframe>
                        </div>
                        <div>
                            <iframe src="https://drive.google.com/file/d/1EVwCavh20HFe5elWwpXtcM6LUUFowlfx/preview" allow="autoplay"></iframe>
                        </div>
                    </div>
                </div>

                <div id="middle-6">
                    <h2>Costos</h2>

                    {/*<div className='options animated zoomIn animate__faster'>
                        <p id='c-opcion-1' className='selected animated bounceIn anim-delay-500ms'
                            onClick={(e) => {
                                document.getElementById("precio-pc").innerHTML = "$ 9,000 MXN";
                                document.getElementById("precio-sc").innerHTML = "$ 3,000 MXN";
                                document.getElementById("precio-scbt").innerHTML = "$ 2,000 MXN";
                                e.target.classList.add("selected");
                                document.getElementById("c-opcion-2").classList.remove("selected");
                                document.getElementById("c-opcion-3").classList.remove("selected");
                            }}
                        >Solo uno</p>
                        <p id='c-opcion-2' className='animated bounceIn anim-delay-1s'
                            onClick={(e) => {
                                document.getElementById("precio-pc").innerHTML = "$ 8,100 MXN";
                                document.getElementById("precio-sc").innerHTML = "$ 2,700 MXN";
                                document.getElementById("precio-scbt").innerHTML = "$ 1,800 MXN";
                                e.target.classList.add("selected");
                                document.getElementById("c-opcion-1").classList.remove("selected");
                                document.getElementById("c-opcion-3").classList.remove("selected");
                            }}
                        >De dos a tres (10%)</p>
                        <p id='c-opcion-3' className='animated bounceIn anim-delay-1s-500ms'
                            onClick={(e) => {
                                document.getElementById("precio-pc").innerHTML = "$ 7,200 MXN";
                                document.getElementById("precio-sc").innerHTML = "$ 2,400 MXN";
                                document.getElementById("precio-scbt").innerHTML = "$ 1,600 MXN";
                                e.target.classList.add("selected");
                                document.getElementById("c-opcion-1").classList.remove("selected");
                                document.getElementById("c-opcion-2").classList.remove("selected");
                            }}
                        >Más de tres (20%)</p>
                    </div>*/}

                    <div className="cards-container">
                        <div className="card">
                            <div className="price">
                                <span>Precio unitario</span>
                                <span className='nota'>*Envío incluido</span>
                                <p id='precio-pc'>$ 5,000 mxn</p>
                            </div>

                            <img src={middle6_1} alt="Ilustración paquete"/>

                            <h3>Paquete completo</h3>
                            <div className="info">
                                <span><b>∙</b> Acceso a descarga del sistema (Combate, Poomsae y Circuito Motriz)</span>
                                <span><b>∙</b> Licencia (clave) para usar el sistema en un dispositivo</span>
                                <span><b>∙</b> 3 controles compatibles</span>
                                <span><b>∙</b> 1 adaptador Bluetooth USB</span>
                            </div>
                        </div>

                        <div className="card">
                            <div className="price">
                                <span>Precio unitario</span>
                                <p id='precio-sc'>$ 1,500 mxn</p>
                            </div>

                            <img src={middle6_2} alt="Ilustración paquete"/>

                            <h3>Sistema completo</h3>
                            <div className="info">
                                <span><b>∙</b> Acceso a descarga del sistema (Combate, Poomsae y Circuito Motriz)</span>
                                <span><b>∙</b> Licencia (clave) para usar el sistema en un dispositivo</span>
                            </div>
                        </div>

                        {/*<div className="card">
                            <div className="price">
                                <span>Precio unitario</span>
                                <p id='precio-scbt'>$ 2,000 MXN</p>
                            </div>

                            <img src={middle6_3} alt="Ilustración paquete"/>

                            <h3>Sistema combate</h3>
                            <div className="info">
                                <span><b>∙</b> Acceso a descarga del sistema (Solo módulo de Combate)</span>
                                <span><b>∙</b> Licencia (clave) para usar el sistema en un dispositivo</span>
                            </div>
                        </div>*/}
                    </div>
                </div>

                <div id="middle-7">
                    <h2>Proceso de compra</h2>

                    <div className='cards-container'>
                        <div className="card">
                            <p className='paso'>1</p>

                            <div>
                                <p className='normal'>Realice su pago en</p>
                                <a onClick={() => logEvent(analytics, "Click en mercadopago")} href="https://link.mercadopago.com.mx/kisystems" target='_blank'>link.mercadopago.com.mx/kisystems</a>
                                <p className='nota'>*Hasta 3 MSI con tarjeta de crédito<br/>*Permite transferencia, débito y pago en Oxxo</p>
                            </div>
                        </div>

                        <div className="card">
                            <p className='paso'>2</p>

                            <div>
                                <p className='normal'>Envíenos captura del pago y su nombre <i>(para la creación de su perfil)</i> a</p>
                                <a onClick={() => logEvent(analytics, "Click en whatsInicio1")} href="https://wa.me/+5212222380533" target='_blank'><i className="fa-brands fa-whatsapp"></i> 22 22 38 05 33</a>
                                <p className='nota'>*Sí adquirió paquete completo también compártanos la dirección de envío</p>
                            </div>
                        </div>

                        <div className="card">
                            <p className='paso'>3</p>

                            <div>
                                <p className='normal'>Recibe usuario y contraseña para que descargue su sistema en</p>
                                <a href="/clientes" target='_blank'>www.kisystems.com.mx/clientes</a>
                                <p className="normal">¡Instale y comience a usar!</p>
                                <p className='nota'>*Sí adquirió paquete completo, recibe sus controles y adaptador Bluetooth (tiempo de envío dado por servicio de paquetería)</p>
                            </div>
                        </div>
                    </div>

                    <a onClick={() => logEvent(analytics, "Click en whatsInicio2")} className='contact-button' href="https://wa.me/+5212222380533" target='_blank'>
                        <i className="fa-brands fa-whatsapp"></i>
                        <span>Contacto</span>
                    </a>
                </div>
            </main>
		</React.Fragment>
    );
};

export default Inicio;